/* eslint-disable prettier/prettier */
<template>
  <div>
    <form v-if="alwaysNewData" @submit.prevent="updateSettings">
      <div class="setting_wrapper">
        <h5 style="color:black">
          Настройки
          <transition name="fade">
            <button
              v-if="isEdditMode"
              class="add_btn btn waves-effect btn-floating"
              type="button"
              @click="edditData()"
            >
              <i class="material-icons">add</i>
            </button>
          </transition>
        </h5>
        <div class="switch">
          <label>
            <input type="checkbox" v-model="isEdditMode" />
            <span class="lever"></span>
          </label>
        </div>
        <button
          type="submit"
          class="btn waves-effect btn_update"
          :disabled="isDisabledBtn"
        >
          Обновить настройки
        </button>
      </div>
      <RecruitingInput
        :values="alwaysNewData"
        :parent="'value'"
        :edditMode="isEdditMode"
        @remove-data="removeData"
        @eddit-data="edditData"
        @update-data="updateData"
      />
    </form>
    <p class="center" v-else>Пожалуйста, подождите загрузки данных...</p>

    <div id="modal" class="modal" ref="modal">
      <div class="modal-content">
        <h4>
          Добавить {{ isNewObj || isNewArr ? 'поле' : 'ключ-значение' }}
          {{ settingWay }}
        </h4>
        <form @submit.prevent="createNewField">
          <div class="row">
            <div class="input-field col s6">
              <input
                id="key"
                type="text"
                class="validate"
                v-model.trim="newKey"
                @keypress="protecingInput($event)"
                :required="isNewArr || isNewObj"
              />
              <label for="key">{{
                isNewObj || isNewArr ? 'Поле' : 'Ключ'
              }}</label>
            </div>
            <div class="input-field col s6" v-if="!isNewObj && !isNewArr">
              <input
                id="value"
                type="text"
                class="validate"
                v-model.trim="newValue"
                required
              />
              <label for="value">Значение</label>
            </div>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="isNewObj" />
              <span>Создать новый обьект настроек</span>
            </label>
            <br />
            <label>
              <input type="checkbox" v-model="isNewArr" />
              <span>Создать новый массив настроек</span>
            </label>
          </div>
          <button type="submit" class="btn">Создать</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import jsonlint from 'jsonlint-mod';
import RecruitingInput from './RecruitingInput';
import API from '../../utils/API';

export default {
  name: 'setting-modal',
  components: {
    RecruitingInput
  },
  data() {
    return {
      isDisabledBtn: true,
      isEdditMode: false,
      modal: null,
      newKey: '',
      newValue: '',
      isNewObj: false,
      isNewArr: false,
      settingWay: '',
      fieldName: '',
      settings: null
    };
  },
  computed: {
    alwaysNewData() {
      return this.settings;
    }
  },
  methods: {
    protecingInput(e) {
      if (!/[a-zA-Zа-яА-ЯёЁ\d-_]/gm.test(e.key)) {
        e.returnValue = false;
      }
    },
    async updateSettings() {
      try {
        jsonlint.parse(JSON.stringify(this.settings));
      } catch (error) {
        /* global M */
        M.toast({
          html: `[Ошибка]: ${error}`,
          classes: 'red darken-3',
          displayLength: 5000
        });
        throw new Error(error);
      }

      const cabinet_api = new API();
      const response = await cabinet_api.setSettings({ ...this.settings });

      if (response.result.success) {
        M.toast({
          html: `[Настройки успешно обновлены!]`,
          classes: 'teal darken-1',
          displayLength: 5000
        });
      } else {
        M.toast({
          html: `[Ошибка при обновление настроек!]`,
          classes: 'red darken-3',
          displayLength: 5000
        });
      }
    },
    updateData(name, value, acc) {
      let rawNewWay = acc + name;
      let newWay = rawNewWay.split('.').slice(1);
      newWay.reduce((ac, item) => {
        if (typeof ac[item] === 'string') {
          return (ac[item] = value);
        }
        return ac[item];
      }, this.settings);
      this.isDisabledBtn = false;
    },
    edditData(name, acc) {
      if (name === undefined) {
        this.settingWay = '';
        this.modal.open();
        return;
      }
      let rawNewWay = acc + name;
      let newWay = rawNewWay.split('.').slice(1);
      this.fieldName = name;
      this.settingWay = newWay.join('.');
      this.modal.open();
    },
    createNewField() {
      if (this.settingWay) {
        let newWay = this.settingWay.split('.');
        newWay.reduce((ac, item) => {
          if (item == this.fieldName) {
            if (!this.newKey) {
              if (Array.isArray(ac[item])) {
                ac[item].push(this.newValue);
              }
              this.$set(ac[item], '', this.newValue);
            } else if (!this.newValue) {
              if (this.isNewArr) {
                this.$set(ac[item], this.newKey, []);
              } else {
                this.$set(ac[item], this.newKey, {});
              }
            } else {
              this.$set(ac[item], this.newKey, this.newValue);
            }
          }
          return ac[item];
        }, this.settings);
      } else {
        if (!this.newValue) {
          if (this.isNewArr) {
            this.$set(this.settings, this.newKey, []);
          } else {
            this.$set(this.settings, this.newKey, {});
          }
        } else {
          this.$set(this.settings, this.newKey, this.newValue);
        }
      }

      this.modal.close();
      this.isDisabledBtn = false;
    },
    removeData(name, acc) {
      let rawNewWay = acc + name;
      let newWay = rawNewWay.split('.').slice(1);
      newWay.reduce((ac, item) => {
        if (item == name) {
          this.$delete(ac, item);
        }
        return ac[item];
      }, this.settings);
      this.isDisabledBtn = false;
    }
  },
  async created() {
    const cabinet_api = new API();
    const response = await cabinet_api.getSettings();
    this.settings = response.result.model;
    this.modal = M.Modal.init(this.$refs.modal, {
      onCloseEnd: () => {
        this.newKey = '';
        this.newValue = '';
        this.isNewObj = false;
        this.isNewArr = false;
        this.settingWay = '';
        this.fieldName = '';
      }
    });
  },
  beforeDestroy() {
    if (this.modal && this.modal.destroy) {
      this.modal.destroy();
    }
  },
  watch: {
    isNewObj(value) {
      if (value) {
        this.newValue = '';
        this.isNewArr = false;
      }
    },
    isNewArr(value) {
      if (value) {
        this.newValue = '';
        this.isNewObj = false;
      }
    }
  }
};
</script>

<style scoped>
.setting_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.switch label input[type='checkbox']:checked + .lever:after {
  background-color: white;
  border: 3px solid #1e9277;
}
button.btn {
  background-color: #1e9277;
}
@media (max-width: 600px) {
  .btn_update {
    width: 130px;
  }
}
h4 {
  font-size: 20px;
}

div.row {
  margin-bottom: 0;
}
.checkbox {
  margin-bottom: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: transform 0.1s;
}
.fade-enter,
.fade-leave-to {
  transform: scale(0);
}
</style>
