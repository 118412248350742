<template>
  <div>
    <div v-for="(value, name) in values" :key="name">
      <div class="input_wrapper" v-if="typeof value === 'string'">
        <label :for="name">{{ name }}:</label>
        <input
          :id="name"
          class="validate"
          :value="value"
          @change="upUpdateToData(name, $event.target.value)"
          @input="upUpdateToData(name, $event.target.value)"
        />
        <transition name="fade">
          <button
            v-if="edditMode"
            class="remove_btn btn waves-effect btn-floating shake-eddit"
            type="button"
            @click="removeData(name)"
          >
            <i class="material-icons">remove</i>
          </button>
        </transition>
      </div>
      <div v-if="typeof value === 'object'" class="group_wrapper">
        <h6>
          {{ name }}
          <transition name="fade">
            <button
              v-if="edditMode"
              class="add_btn btn waves-effect btn-floating"
              type="button"
              @click="edditData(name)"
            >
              <i class="material-icons">add</i>
            </button>
          </transition>
          <transition name="fade">
            <button
              v-if="edditMode"
              class="remove_btn btn waves-effect btn-floating shake-eddit"
              type="button"
              @click="removeData(name)"
            >
              <i class="material-icons">remove</i>
            </button>
          </transition>
        </h6>

        <recurs-field
          name="recurs-field"
          :parent="name"
          :values="value"
          :edditMode="edditMode"
          @remove-data="removeData"
          @eddit-data="edditData"
          @update-data="upUpdateToData"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'recurs-field',
  props: ['values', 'parent', 'edditMode'],
  methods: {
    upUpdateToData(name, value, acc = '') {
      this.$emit(
        'update-data',
        name,
        ('' + value).trim(),
        this.parent + '.' + acc
      );
    },
    edditData(name, acc = '') {
      this.$emit('eddit-data', name, this.parent + '.' + acc);
    },
    removeData(name, acc = '') {
      this.$emit('remove-data', name, this.parent + '.' + acc);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  line-height: 25px;
  width: 100%;
  max-width: 25px;
  height: 25px;
  box-sizing: border-box;
  i {
    width: auto;
    font-size: 17px;
    line-height: 25px;
  }
}
.add_btn {
  margin-left: 10px;
  background-color: #1e9277;
}

.remove_btn {
  margin-left: auto;
  background-color: #b90e0e;
}

.input_wrapper {
  display: flex;
  align-items: center;
  label {
    line-height: 50px;
    margin-right: 10px;
    color: #3aa7d2;
    font-weight: 800;
    font-size: 14px;
  }
  input:focus {
    border-bottom: 1px solid #1e9277;
    box-shadow: 0 1px 0 0 #1e9277;
  }
}

.group_wrapper {
  margin: 20px 0 0 0;
  padding: 5px 0 5px 20px;
  border-left: 3px solid #1e9277;
  border-top: 3px solid #1e9277;
}
h6 {
  display: flex;
  align-items: center;
  color: #3a53d2;
  margin-top: -25px;
  padding: 10px 0 10px 10px;
  font-weight: 600;
  background-color: white;
}
input {
  color: rgba(0, 0, 0, 0.87);
}

.fade-enter-active,
.fade-leave-active {
  transition: transform 0.1s;
}
.fade-enter,
.fade-leave-to {
  transform: scale(0);
}
</style>
